export const MESSAGE_TYPES = {
    LOGGED_IN_STATE_CHANGED: "logged_in_state_changed",
    ADAPTIVE_STATE_CHANGED: "adaptive_state_changed",
    DETAILED_ADAPTIVE_STATE_CHANGED: "detailed_adaptive_state_changed",
    SHOW_LOADER: "show_loader",
    SHOW_LOGIN: "show_login",
    CLOSE_POPUP: "close_popup",
    APP_CLICKED: "app_clicked",
    TAPPED_HAMBURGER: "tapped_hamburger",
    TAPPED_SEARCH: "tapped_search",
    UPDATE_SECONDARY_NAVBAR: "update_secondary_navbar",
    ERROR: "error",
    WINDOW_SIZE_CHANGED: "window_size_chaged",
    FIX_HEADER_STATE_CHANGED: "fixed_header_state_changed",
    SET_SEARCH_BAR_SEARCH_TYPE: "set_search_bar_search_type",
    REGISTER_TO_WINDOW_EVENTS: "register_for_windows_scroll",
    ME_STATE_CHANGED: "me_state_changed",
    PROPERTY_LISTINGS_RESULT_UPDATED: "property_listings_result_updated",
    PROPERTY_LISTINGS_RESULT_TEXT_UPDATED: "property_listings_result_text_updated",
    UPDATE_ABOVE_FIXED_HEADER_TEXT: "update_above_fixed_header_text",
    SEARCH_BAR_LOADED: "search_bar_loaded",
    UPDATE_SEARCH_CRITERIA: "update_search_criteria",
    LATEST_SEARCH_CRITERIA_UPDATED: "latest_search_criteria_updated",
    TAPPED_DONE_FILTER_COMPONENT: "done_filter_component",
    PREVENTDEFAULTONAPPCLICK: "popup_opened",
    SHOW_SECONDARY_OVERLAY: "show_overlay",
    NAVIGATED: "navigated",
    SHOW_IN_APP_TOAST: "show_in_app_toast",
    SHOW_CONTACT_AGENT: "show_contact_agent",
    SHOW_CONTACT_AGENT_BUTTONS: "show_contact_agent_buttons",
    SHOW_CLOSE_ACCOUNT: "show_close_account",
    SHOW_CONFIRM_ACCOUNT: "show_confirm_account",
    SAVED_SEARCHES_UPDATED: "saved_searches_updated",
    SHOW_YES_NO_POPUP: "show_yes_no_popup",
    YES_NO_POP_RESULT: "yes_no_popup_result",
    CONTACT_AGENT_COMPLETED: "contact_agent_completed",
    FOUR_0_FOUR: "404",
    REDIRECT: "301",
    NAVIGATION_STARTING: "navigation_starting",
    SET_FOOTER_BOTTOM: "set_footer_bottom",
    CLOSE_COOKIES_DISCLAIMER: "close_cookies_disclaimer",
    FAVOURITES_UPDATED: "favourites_updated",
    MENU_BUTTON_PERFORM_ACTION: "menu_button_perform_action",
    UPDATE_LOGO_BUTTON_BADGE_COUNT: "update_logo_button_badge_count",
    FIXED_HEADER_SEARCH_BAR_INITIALIZED: "fixed_header_search_bar_initialized",
    SHOW_MAP_SEARCH: "show_map_search",
    UPDATE_SEARCH_FROM_MAP: "update_search_from_map",
    REQUEST_SEARCH_BAR_MOBILE_COMPONENT_PARAMETERS: "request_searchbarcomponent",
    SEND_SEARCH_BAR_MOBILE_COMPONENT_PARAMETERS: "send_searchbarcomponent",
    IS_FOOTER_VISIBLE: "is_footer_visible",
    IS_NAVBAR_VISIBLE: "is_navbar_visible",
    SHOW_BOTTOM_UPDATE_SEARCH_BUTTON: "show_bottom_update_search_button",
    SHARE_CLICKED: "share_clicked",
    BOTTOM_UPDATE_SEARCH_BUTTON_CLICKED: "bottom_update_search_button_clicked",
    SHOW_MOBILE_FILTER: "show_mobile_filter",
    PERFORMED_PLACE_SEARCH: "performed_place_search",
    SHOW_PHOTOSWIPE: "show_photoswipe",
    TRACK_LOGGEDIN_SESSION: "track_login_session",
    DCP_LOADED: "dcp_loaded",
    GTM_LOADED: "gtm_loaded",
    ENQUIRIES_UPDATED: "enquiries_updated",
    HLL_USAGE_AND_ALLOWANCE_UPDATED: "hll_UAA",
    HLL_UPDATED: "hll_updated",
    SHOW_TEXT_POPUP: "show_text_popup",
    SHOW_RATE_POPUP: "show_rate_popup"
}
