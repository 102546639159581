import { Injectable } from '@angular/core';
import { ISubscriber, IMessage } from './model/model';


@Injectable({providedIn: 'root'})
export class Messenger {
    private subscribers: Array<ISubscriber>;

    constructor() {
        this.subscribers = new Array<ISubscriber>();
    }

    Subscribe(subscriber: ISubscriber): void {
        this.subscribers.push(subscriber);
    }

    Unsubscribe(subscriber: ISubscriber): void {
        var index = this.subscribers.indexOf(subscriber);
        if (index > -1) {
            this.subscribers.splice(index, 1);
        }
    }

    Send(message: IMessage): void {
        var subscribersToUse = this.subscribers.filter(s => s.messageType == message.messageType);
        for (var i = 0; i < subscribersToUse.length; i++) {
            subscribersToUse[i].On(message.messageData);
        }
    }
}