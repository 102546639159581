
import { Me } from "./model/me";
import { IMessageData } from "src/app/services/messenger.module/model/model";
import { ActionAndContext, ActionAndContextWithInstanceId } from "./actionAndContext";
import { PlaceSearchCriteria } from "./model/placeSearchCriteria";
import { SearchComponentModel } from "./model/searchComponentModel";
import { ElementRef } from "@angular/core";

import { AgentListing } from "../pages/members/Pages/manage.listings.container.component/model";
import { ECommerceTrackingItem } from "src/app/services/tracking.module/model/eCommerceTrackingItem";
import { YesNoPopupComponentModel } from "../modules/lazy.components.modules/yes-no-popup.component.module/model";

export class TrueFalseMessageData implements IMessageData {
    constructor(public isTrue: boolean) { }
}

export class MeStateChangedMessageData implements IMessageData {
    constructor(public me: Me) { }
}

export class AdaptiveStateChangedMessageData implements IMessageData {
    constructor(public isMobile: boolean) { }
}

export class NavigatedMessageData implements IMessageData {
    constructor(public wasBackNavigation: boolean) { }
}

export class SavedSearchesUpdatedMessageData implements IMessageData {
    constructor(public latestSearch: PlaceSearchCriteria, public searches: Array<PlaceSearchCriteria>) { }
}

export class ErrorMessageData implements IMessageData {
    constructor(public errorType: string, public retryAction: Function, public retryContext: any) { }
}

export class RedirectData implements IMessageData {
    constructor(public url: string) { }
}


export class WindowSizeChangedMessageData implements IMessageData {
    constructor(public newWidth: number, public newHeight: number) { }
}

export class SetFooterBottomMessageData implements IMessageData {
    constructor(public bottom: number) { }
}

export class RegisterFunctionForWindowEventMessageData implements IMessageData {
    constructor(public action: ActionAndContextWithInstanceId<any>, public eventType: string, public isRegistration: boolean) { }
}

export class ShowLoginMessageData implements IMessageData {
    showOverlay: boolean;
    isRegistration: boolean;
    shouldSaveSearch?: boolean = false;
    shouldTurnOnAlerts?: boolean = false;
    searchLat?: number = null;
    searchLon?: number = null;
    registerText?: Array<string> = null;
    prefillEmail?: string = null;
    autoSendMagicLinkEmail?: string = null;
    explicitRedirectUrl?: string = null;
}

export class MenuButtonPerformActionMessageData implements IMessageData {
    constructor(public performActionButtonId: string) { }
}

export class UpdateBadgeCountMessageData implements IMessageData {
    constructor(public badgeCount: number) { }
}

export class TappedHamburgerMessageData implements IMessageData {
    constructor(public isOpen: boolean) { }
}

export class ShowBottomUpdateSearchButtonMessageData implements IMessageData {
    constructor(public isVisible: boolean, public saveOrUpdateMode: string) { }
}

export class ShowInAppToastMessageData implements IMessageData {
    constructor(public text: string) { }
}

export class ShowSecondaryOverlayMessageData implements IMessageData {
    constructor(public isVisible: boolean, public elementRef: ElementRef, public customCssText = undefined) { }
}

export class YesNoPopupResultMessage implements IMessageData {
    constructor(public isYes: boolean, public popupId: number, public data: any) { }
}

export class ShowYesNoPopupMessageData implements IMessageData {
    constructor(public data: YesNoPopupComponentModel) { }
}

export class ShowMobileFilterMessageData implements IMessageData {
    constructor(public searchComponentModel: SearchComponentModel, public heading: string, public mode: string, public searchCriteria: PlaceSearchCriteria, public resolvedSearchMobileComponent: any) { }
}

export class ShowContactAgentMessageData implements IMessageData {
    constructor(public mode: string, public campaignId: number, public listingId: number, public listingTypeId: number, public agentName: string, public agentPhone: string, public isForSale: boolean, public propertyUrl: string, public price: number, public eCommerceTracking: ECommerceTrackingItem, public resolvedContactAgentComponent: any) { }
}

export class ShowPhotoSwipeMessageData implements IMessageData {
    constructor(public isVisible: boolean, public imageUrls: Array<string>, public imageSize: string, public indexChangedCallback: ActionAndContext<number>, public startingIndex: number, public additionalCounterText: string, public resolvedPhotoSwipeComponent: any) { }
}

export class ContactAgentCompletedMessageData implements IMessageData {
    constructor(public isPhone: boolean, public contactAgentMode: string, public message: string, public conversionPrice: number) { }
}

export class ShowRatePopupMessageData implements IMessageData {
    constructor(public adminAreaPath, public localityPath: string, public adminArea: string, public locality: string, public resolvedRateComponent: any) { }
}

export class CloseAccountMessageData implements IMessageData {
    constructor(public resolvedCloseAccountComponent: any) { }
}

export class ConfirmAccountMessageData implements IMessageData {
  constructor(public resolvedConfirmAccountComponent: any) { }
}


export class ShowHLLDetailsMessageData implements IMessageData {
    constructor(public listing: AgentListing, public resolvedContactAgentComponent: any) { }
}

export class HllUpdatedMessageData implements IMessageData {
    constructor(public placeId: number, public newHllId: number, public tags: string, public listingType: string) { }
}

export class ShowTextPopupMessageData implements IMessageData {
    constructor(public title: string, public text: string, public width: string, public height: string) { }
}

export class ShowLoaderMessageData implements IMessageData {
    constructor(public isTrue: boolean, public message: string) { }
}

export class DebugMessageData implements IMessageData {
    constructor(public debugMessage: string) { }
}
